.submitbtn{
    background-color: #BE8E5E;
}

.cancelBtn{
    background: #C5D3C7;
    border: 1px solid #C5D3C7;
}
.mb7{
    margin-bottom: 2rem;
    padding: 0 1rem;
}

.mb7 span {
    font-family: Raleway;
    font-weight: 400;
    color: #000000;
    font-size: 1.4rem;
    margin-left: 0;
}

