@font-face {
    font-family: Raleway;
    src: url(../Fonts/RALEWAY-VARIABLEFONT_WGHT.TTF);
}

.noOrders {
    min-height: calc(100vh - 7rem - 30rem);
}

.emptyOrders {
    width: 100%;
    height: calc(40vh);
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyOrders div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.emptyOrdersHeading {
    font-weight: 600;
    font-size: 2rem;
}

.emptyOrderDesc {
    font-size: 1.5rem
}


.my-order-detail {
    width: 90%;
    margin: auto;
}

.myorders-container .my-orders-subcontainer {
    width: 60%;
    margin: auto;
    padding: 4rem 0;
    min-height: calc(100vh - 37rem);
}

.myorders-container .my-orders-subcontainer h1 {
    font-family: Raleway;
    font-weight: 600;
}

.myorders-container .my-orders-subcontainer .ordertabs {
    font-family: Raleway;
    margin-top: 2.5rem;
    margin-bottom: 2.57rem;
    display: flex;
}

.can-click {
    cursor: pointer;
}

.active-tab {
    background-color: #135478;
    color: #fff;
}

.myorders-container .my-orders-subcontainer .ordertabs div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.875rem;
    width: 12rem;
    border-radius: 40px;
    text-align: center;
    font-size: 14px;
}

.order-detail-container {
    cursor: pointer;
}

.order-detail-container:hover{
    background-color: #fbfbfb;
}

.order-num-date {
    font-family: Raleway;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.order-num-date .order-num {
    font-weight: 600;
}

.back-to-orders-btn {
    cursor: pointer;
    z-index: 9999999;
    bottom: 0;
    width: 100%;
    height: 4rem;
    color: #FFFFFF;
    background: #ED8D7D;
    border: 1px solid #ED8D7D;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
}



@media (max-width: 560px) {
    .order-num-date .order-date {
        text-align: right;
    }
}

.my-order-separator {
    color: #DEE2E6;
    /* color: black; */
    margin: 0;
}

.vendor-price {
    font-family: Raleway;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
}

.vendor-price p {
    font-weight: 600;
    margin-top: 0.1rem;
    margin-bottom: 0;
}

.status-accepted {
    color: #2CB673 !important;
    font-weight: 600 !important;
}

.status-other {
    color: #DC3545 !important;
    font-weight: 600 !important;
}

.order-price {
    font-weight: 600;
}

@media (max-width: 680px) {
    .myorders-container .my-orders-subcontainer {
        width: 80%;
    }
}