.cart-modal-head {
    color: #1A203D;
    font-weight: 600;
    font-size: 2rem;
    font-family: Raleway;
    text-align: left;
    justify-content: left;
}

.cart-modal-body{
    margin-top: 1rem;
    margin-bottom: 2rem;
}