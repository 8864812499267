@font-face {
    font-family: Raleway;
    src: url(../Fonts/RALEWAY-VARIABLEFONT_WGHT.TTF);
}

@font-face {
    font-family: Prata;
    src: url(../Fonts/Prata-Regular.ttf);
}

img {
    max-width: 100%;
}

.main-hr {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid #C2C9D1;
}

.main-content {
    margin-top: 5rem;
    margin-left: 15%;
    margin-bottom: 5rem;
}

.col-one {
    width: 45rem;
}

/* .col-two {
    width: 45%;
} */

.vendor-card {
    margin-left: auto;
    margin-right: auto;
}

.vendor-image {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    object-position: center;
}

.vendor-card-barrier {
    width: 30rem;
    height: 3rem;
    background-color: #135478;
    color: #FFFFFF;
    text-align: center;
    line-height: 1.5;
    margin-left: 6.25rem;
    margin-top: -1.6rem;
    font-family: Raleway;
    font-weight: 300;
    font-size: 2rem;
    text-transform: uppercase;
    z-index: 999;
}

.vendor-details {
    background-color: #FFFFFF;
    color: #000000;
    font-family: Raleway;
    margin: 3rem 2rem;
}

.vendor-details p {
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 1.3;
}

.package {
    display: flex;
    flex-flow: row;
    margin: 0.5rem 2rem 3rem 2rem;
}

.vendor-package-image {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    object-position: center;
}

.package-info {
    width: 100%;
    margin-right: 2rem;
    margin-left: 2rem;
}

.package-info h4 {
    font-family: Raleway;
    color: #000000;
    font-weight: 600;
    line-height: 1.3;
}

.package-info p {
    font-family: Raleway;
    color: #000000;
    font-weight: 400;
    line-height: 1.3;
    font-size: 1.5rem;
}

.package-price {
    text-align: right;
    width: 13rem;
}

.package-price h4 {
    font-family: Raleway;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0rem;
    font-size: 1.8rem;
    margin-right: 1rem;
}

.package-price span {
    color: #787885;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Raleway;
}

.vendor-menu {
    width: 15rem;
    height: 4.5rem;
    background-color: #ED8D7D;
    border: 1px solid #ED8D7D;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: Raleway;
    font-weight: 400;
    display: block;
    margin: 0 auto;
}

.label-price {
    position: absolute;
    top: 48%;
    right: 40%;
    font-size: 1.6rem;
    color: #FFFFFF;
    z-index: 999;
}

.label-people {
    position: absolute;
    top: 48%;
    right: -33%;
    font-size: 1.6rem;
    color: #FFFFFF;
    z-index: 999;
}

.overlay {
    z-index: 999;
    margin-top: -11rem;
    width: 100%;
    border-bottom: 5px solid #135478;
}

.card {
    border: 1px solid #DADADA;
}

.vendorError {
    height: 450px;
}

@media (max-width: 768px) {
    .package-info {
        width: 25rem;
    }

    .main-content {
        width: 100%;
        margin-top: 0;
        margin-left: 0;
    }

    .desktop-only {
        display: none;
    }

    .col-one {
        width: 100%
    }

    .card {
        border: none;
    }

    .col-two {
        width: 100%;
    }

    .label-price {
        right: 34%;

    }

    .label-people {
        right: -32%
    }

    .vendor-menu {
        width: 17rem;
    }

    .strip-line {
        border: 1px solid #DADADA;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    hr {
        margin-right: 10%;
        margin-left: 10%;
        border-top: 1px solid #FFFFFF;
    }
    .package-price {
        width: auto;
    }
    .package-price h4 {
        font-family: Raleway;
        color: #000000;
        font-weight: 600;
        margin-bottom: 0rem;
        text-align: center;
    }

    .main-hr {
        display: none;
    }

    .overlay {
        margin-top: -10.5rem;
    }

    .package {
        margin: 3rem 2rem;
    }
}