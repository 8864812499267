@font-face {
    font-family: Raleway;
    src: url(../Fonts/RALEWAY-VARIABLEFONT_WGHT.TTF);
}

@font-face {
    font-family: Prata;
    src: url(../Fonts/Prata-Regular.ttf);
}

.packageListError {
    margin-bottom: 50px;
}

.vendor-main-package-parent:nth-child(3) {
    margin-top: 50px;
}

.vendor-main-package-parent {
    width: 65%;
    margin: auto;
    font-family: Raleway;
}

.vendor-main-package-options-parent {
    width: 85%;
    margin: auto;
    font-family: Raleway;
}

.separator {
    width: 50%;
    margin: auto;
}

.vendor-main-package-options-parent .vendor-main-package {
    margin: 2rem auto;
}

.vendor-main-package-parent .vendor-main-package {
    margin: 2rem auto;
}

/* .vendor-main-package-parent:nth-last-child(3) {
    margin-bottom: 270px;
} */

.vendor-main-package {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
}

.vendor-mp-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    bottom: -1px;
    object-fit: cover;
    object-position: center;
}

.cart-details .vendor-mp-overlay {
    display: block;
}

.vendor-mp-overlay {
    position: relative;
    height: 30rem;
    border-bottom: 5px solid #135478;
    display: none;
}

.svp-overlay {
    position: absolute;
    width: 100%;
    z-index: 999;
    bottom: -1px;
    margin-top: 4rem;
}

.mp-description {
    position: relative;
    width: 100%;
    z-index: 9999;
}

.mp-label-price {
    position: absolute;
    bottom: 35px;
    margin-left: 2rem;
    font-size: 2rem;
    color: #FFFFFF;
    z-index: 999;
    font-family: Raleway;
}

.mp-label-people {
    font-size: 2rem;
    color: #FFFFFF;
    z-index: 999;
    position: absolute;
    bottom: 35px;
    right: 2.2rem;
    font-family: Raleway;
}

.vendor-mp-description {
    width: 100%;
    background-color: #F6F6F6;
    margin-top: -4rem;
}

.vendor-description-cart-details .vendor-mp-description {
    background-color: #FFFFFF;
}

.vendor-mp-label-price {
    float: left;
    margin-left: 2rem;
    margin-top: -3.5rem;
    font-size: 2rem;
    color: #FFFFFF;
    z-index: 999;
    position: relative;
}

.vendor-mp-label-people {
    float: right;
    margin-right: 2.2rem;
    margin-top: -3.5rem;
    font-size: 2rem;
    color: #FFFFFF;
    z-index: 999;
    position: relative;
}

.vendor-mp-card-barrier {
    position: relative;
    width: 55rem;
    height: 4rem;
    background-color: #135478;
    color: #FFFFFF;
    text-align: center;
    line-height: 2;
    font-family: Raleway;
    font-weight: 300;
    font-size: 2rem;
    text-transform: uppercase;
    z-index: 999;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    /* left: 0;
    right: 0; */
}

@media print {
    .dashboard-main-div {
        background-color: #107be6;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .vendor-mp-card-barrier {
        /* background-color: #135478; */
        /* color: #FFFFFF; */
        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }
}

.vendor-mp-details {
    margin: 0 2rem 0 2rem;
}

.vendor-mp-details p {
    text-align: left;
    overflow: hidden;
    padding-top: 4rem;
    padding-bottom: 2rem;
    color: #000000;
    font-family: Raleway;
    line-height: 1.3;
    font-weight: 400;
}

.selected-vendor-packages {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
}

.cart-detail-btn-container {
    margin: 2rem auto;
}

.cart-detail-btn {
    margin-top: 1.9rem;
    width: 100%;
    height: 4rem;
    color: #FFFFFF;
    background: #C5D3C7;
    border: 1px solid #C5D3C7;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
}

.check-out-cart-btn {
    background-color: #ED8D7D;
}

.empty-cart-btn {
    background-color: #C5D3C7;
}

.svp-package-info {
    margin-left: 2rem;
}

.row-package-separator {
    border-bottom: 1px solid #C2C9D1;
}

.svp-package-info h4 {
    font-family: Raleway;
    font-weight: 700;
    font-style: normal;
    color: #000000;
    font-size: 1.6rem;
    line-height: 1.3;
}

.svp-package-info p {
    font-family: Raleway;
    line-height: 1.3;
    font-weight: 400;
    font-style: normal;
    color: #000000;
    font-size: 1.5rem;
}

.svp-package-image {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    object-position: center;
}

.svp-package {
    display: flex;
    flex-flow: row;
    margin: 2rem 2rem;
}

.cm-main .svp-package {
    margin: 2rem 1rem;
}

.svp-package-item {
    margin: 0 2rem 10rem 2rem;
    font-weight: 400;
    font-family: Raleway;
    font-style: normal;
    font-size: 1.5rem;
}

.svp-item label {
    font-size: 1.5rem;
    line-height: 1.3;
    font-family: Raleway;
    font-weight: 400;
    color: #000000;
    margin: 0 0 0 0;
}

.svpi-package-price {
    position: absolute;
    bottom: 0;
    margin: 0 0rem 2rem 2rem;
    width: 90%;
}

.svpi-package-price h3 {
    font-family: Raleway;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 3;
    width: 15rem;
}

.svpi-vendor-menu-btn {
    width: 12rem;
    height: 4.5rem;
    background-color: #ED8D7D;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ED8D7D;
    border-radius: 4px;

    color: #FFFFFF;
    font-family: Raleway;
    font-weight: 400;
    float: right;
    margin-left: 1rem;
}

.svp-item {
    font-size: 1.4rem;
    margin-top: 0.5rem;
}

.svpi-pp-item {
    text-align: left;
    width: 13.5rem;
}

.svpi-pp-item h4 {
    font-family: Raleway;
    font-weight: 600;
    font-style: normal;
    font-size: 1.7rem;
    text-align: right;
    margin-right: 0.3rem;
}

.svpi-pp-item span {
    font-family: Raleway;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: #787885;
    float: right;
    margin-top: -0.3rem;
    /* border: #000000 solid 1px; */
}

.main-row {
    border-bottom: 1px solid #C2C9D1;
}

.img-container {
    width: 100%;
    height: 30rem;
    /* max-width: 600px; */
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
    border-bottom: 5px solid #135478;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 18.27%, rgba(0, 0, 0, 0.75) 89.42%);
}

.img-container::after {
    content: "";
    display: block;
    padding-bottom: calc(900% / 16);
}

.img-true {
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

/* .img-container > * {
  position: absolute;
  top: var(--offset, 0);
  left: var(--offset, 0);
  width: calc(100% - 2 * var(--offset, 0px));
  height: calc(100% - 2 * var(--offset, 0px));
  object-fit: contain;
} */

.img-blur {
    position: absolute;
    --blur: 20px;
    object-fit: cover;
    filter: blur(var(--blur));
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .cart-details .vendor-mp-overlay {
        height: 30rem;
    }

    .vendor-mp-overlay {
        position: relative;
        height: 25rem;
        border-bottom: 5px solid #135478;
        display: block;
    }

    .vendor-main-package-parent {
        width: 90%;
    }

    .vendor-main-package-parent:nth-last-child(3) {
        margin-bottom: 50px;
    }

    .vendor-main-package {
        width: 100%;
        margin-top: 0;
    }

    .cart-detail-btn-container {
        margin: 2rem auto;
    }

    .separator {
        width: 95%;
    }

    .vendor-mp-image {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        bottom: -1px;
        object-fit: cover;
        object-position: center;
    }

    .vendor-mp-card-barrier {
        width: 32rem;
    }

    .selected-vendor-packages {
        width: 100%;
        margin-bottom: 0;
    }

    .svpi-vendor-menu-btn {
        margin-left: 1rem;
    }

    .svp-overlay {
        margin-top: 10rem;
        position: absolute;
        width: 100%;
        z-index: 999;
        bottom: -1px;
    }

    .vendor-mp-label-price {
        margin-top: -3.5rem;
        font-size: 1.6rem;
    }

    .vendor-mp-label-people {
        margin-right: 2rem;
        margin-top: -3.5rem;
        font-size: 1.6rem;
    }

    .svpi-pp-item {
        text-align: left;
        margin-left: -1rem;
    }

    .svpi-pp-item h4 {
        font-family: Raleway;
        font-weight: 600;
        font-style: normal;
    }

    .svp-main {
        border-bottom: 1px solid #C2C9D1;
        /* margin-left: 1.5rem; */
    }

    .main-row {
        border-bottom: 1px solid #FFFFFF;
        /* display: none; */
    }

    .cm-main .svp-package {
        margin: 2rem 1rem;
    }

    .cm-main .svp-package-item {
        margin: 0 1rem 0 1rem !important;
    }

    .cart-row {
        padding: 0 1rem 0 1rem !important;
    }

    .margin-lr-0 {
        margin-top: 7rem;
    }

    .img-container {
        display: none;
    }
}

@media (max-width: 375px) {
    .vendor-main-package-parent .vendor-main-package .col {
        font-size: 14px;
    }
}

.width20 {
    width: 28%;
    font-family: Raleway;
    font-weight: 400;
    color: #000000;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.8;
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
}

.width50 {
    width: 100%;
    font-family: Raleway;
    font-weight: 400;
    color: #000000;
    font-size: 1.5rem;
    line-height: 3;
    white-space: nowrap;
    overflow: hidden;
}

.width100 {
    width: 100%;
    padding: 0.6rem;
    /* margin-bottom: 3rem; */
}

.margin-lr-1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.margin-lr-0 {
    margin-left: 0rem;
    margin-right: 0rem;
}


/* Cart Modal Classes starts here */

.cm-pricing-div {
    text-align: right;
}

.cm-exkl-div {
    font-size: 11px;
}

.textAlignEnd {
    text-align: end;
    line-height: 2.5;
}

.cm-main .svp-package-item {
    margin: 0 1rem 0 1rem;
}


/* .cm-main {
    height: 25rem;
} */

.cm-allergies-box {
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 1.4rem;
}

.cart-quantity {
    font-family: Raleway;
    font-weight: 400;
    color: #000000;
    font-size: 1.5rem;
    line-height: 3;
}

.cart-allergy {
    font-family: Raleway;
    font-weight: 400;
    color: #000000;
    font-size: 1.5rem;
}

.cm-pricing-div h4 {
    font-family: Raleway;
    font-weight: 600;
    font-style: normal;
    font-size: 2rem;
}

.cm-pricing-div span {
    font-family: Raleway;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: #787885;
    float: right;
    margin-top: -0.3rem;
}

.cart-row {
    padding: 0 1rem 0 1rem;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

/* body.modal-open {
    overflow: hidden;
    position: fixed;
} */

/* body.modal-open {
    overflow: hidden;
    position: fixed;
} */