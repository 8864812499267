
.terms-and-conditions {
    width: 90%;
    margin: 0 auto;
}

.terms-and-conditions .tac-content {
    margin: 6rem;
}

.terms-and-conditions .tac-content p a {
    text-decoration: none;
}

.terms-and-conditions .tac-content p a:hover {
    text-decoration: underline;
}

.terms-and-conditions .tac-content h2 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 132%;
    color: #000000;
}

.terms-and-conditions .tac-content p {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    color: #212529;
    line-height: 1.5;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.terms-and-conditions .tac-content h4 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    color: #000000;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .terms-and-conditions {
        width: 100%;
        margin: 0 auto;
    }
    .terms-and-conditions .tac-content {
        margin: 2rem;
    }
}