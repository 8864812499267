
.filter-header {
    background: #F6F6F6;
    padding: 3rem 4rem;
}

.filter-header-col {
    display: flex; 
    flex-direction: row;
    height: 5.5rem;
}

.fh-icon {
    width: 2rem;
}
.clickable {
    cursor: pointer;
}

.filter-header-col-one {
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    height: 4rem;
    width: 100%;
    line-height: 2.5;
    font-family: 'Raleway';
    border: 1px solid #DEE2E6;
    border-radius: 8px;
}

.filter-header-col-sub-one {
    padding-left: 2rem;
    justify-content: left;
}

.filter-header-col-two {
    display: flex;
    flex-direction: row; 
}

.filter-header-col-sub-two {
    justify-content: left;
    width: 100%;
    line-height: 2.5;
    padding: 0px 15px 0px 15px;
    font-family: 'Raleway';
    background: #FFFFFF; 
    height: 4rem;
    margin-left: 3rem;
    border: 1px solid #DEE2E6;
    border-radius: 8px;
}

.filter-header-col-sub-two-one {
    text-align: left;
    justify-content: left;
    width: 100%;
    line-height: 2.5;
    padding: 0px 15px 0px 15px;
    font-family: 'Raleway';
    background: #FFFFFF; 
    height: 4rem;
    margin-left: 3rem;
    border: 1px solid #DEE2E6;
    border-radius: 8px;
}

.row-second {
    display: flex;
    flex-direction: row;
}

.filter-prefernces-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DEE2E6; 
    border-radius: 6px;
    padding: 5px; 
    text-transform: capitalize;
    height: 3.5rem;
    text-align: center;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1rem;
    margin-right: 1rem;
}

.filter-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DEE2E6; 
    border-radius: 6px; 
    padding: 5px; 
    text-transform: capitalize;
    height: 3.5rem;
    text-align: center;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1rem;
    margin-right: 1rem;
}

.filter-head-button {
    width: 5rem; 
    height: 4rem; 
    text-align: center; 
    cursor: pointer; 
    background-color: #ED8D7D;
    border-radius: 8px; 
    border: 1px solid #ED8D7D;
    background-image: url('../Images/filter_icon.png');
    background-repeat: no-repeat;
    background-position: center;
}

span {
    margin-left: 0.5rem;
    padding-right: 0.5rem;
}

@media (max-width: 768px) {
    .filter-header-col {
        display: flex;
        flex-direction: column;
        height: 3.8rem;
    }
    .filter-header-col-two {
        height: 1rem;
        width: 90%;
    }
    .filter-header-col-one {
        background: #F6F6F6;
        border: 1px solid #F6F6F6;
        width: 42rem;
    }
    .filter-header-col-sub-two {
        background: #F6F6F6;
        border: 1px solid #F6F6F6;
        width: 43%;
        margin-left: 0.5rem;
        white-space: nowrap;
    }
    .filter-header-col-sub-two-one {
        background: #F6F6F6;
        border: 1px solid #F6F6F6;
        width: 60%;
        margin-left: 18.2rem;
        margin-top: -0.9rem;
        white-space: nowrap;
    }
    .filter-head-button {
        margin-top: -1rem;
        background-color: #F6F6F6;
        border: 1px solid #F6F6F6;
        background-image: url('../Images/filter-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
    .filter-header {
        padding: 1rem;
    }
    .row-second {
        display: flex;
        flex-direction: row;
        padding-right: calc(var(--bs-gutter-x) * 1);
        padding-left: calc(var(--bs-gutter-x) * 1);
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        white-space: nowrap;
    }
    .filter-prefernces-tags {
        background: #DEE2E6;
        border-radius: 6px;
        padding: 4px 4px 3px 4px;
        text-transform: capitalize;
        height: 2.7rem;
        text-align: center;
        font-family: Raleway;
        font-weight: 400;
        margin-right: 1rem;
    }
    .filter-tags {
        background: #DEE2E6;
        border-radius: 6px;
        padding: 4px 4px 3px 4px;
        text-transform: capitalize;
        height: 2.7rem;
        text-align: center;
        font-family: Raleway;
        font-weight: 400;
        margin-right: 1rem;
    }
    .filter-prefernces-tags span {
        font-size: 1.2rem;
    }
    .filter-tags span {
        font-size: 1.2rem;
    }
    .filter-button-col {
        margin-bottom: 1rem;
    }
}