

.filter-input {
    position: relative;
}

.filter-image {
    position: relative;
    top: 1rem;
    left: 1rem;
    z-index: 999;
}

.dp-class .filter-image {
    top: 3rem;
}

.filter-text,.filter-text, .filter-input .react-datepicker__input-container input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    padding: 10px 5px 5px 40px;
    border: 1px solid #DADADA;
    border-radius: 4px;
    transition: all .3s cubic-bezier(.64,.09,.08,1);
    background: linear-gradient(
    180deg,hsla(0,0%,100%,0) 95%,#fff 0);
    background-position: -200px 0;
    background-size: 200px 100%;
    background-repeat: no-repeat;
    color: #000;
    font-family: Raleway;
    font-size: 1.4rem;
    font-weight: 500;
}

.filter-input .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    top: 0px;
    left: 16.5rem !important;
}

.filter-input .react-datepicker__navigation--previous {
    top: 0px !important;
    left: 6.5rem !important;
}
.filter-button {
    bottom: 0;
    width: 100%;
    height: 4rem;
    color: #FFFFFF;
    background: #ED8D7D;
    border: 1px solid #ED8D7D;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
}

.filter-cancel-button {
    bottom: 0;
    width: 100%;
    height: 4rem;
    color: #FFFFFF;
    background: #C5D3C7;
    border: 1px solid #C5D3C7;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
}

.filter-button:disabled {
    opacity: 0.7;
}

.filter-modal-body {
    margin-left: 1rem;
    margin-right: 1rem;
}

.filter-modal-body label {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2;
    color: #000000;
}

.filter-modal-head {
    color: #1A203D;
    font-weight: 600;
    font-size: 2rem;
    padding-left: 2rem;
    font-family: Raleway;
    text-align: left;
    justify-content: left;
}

.modal-content {
    border-radius: 6px !important;
}

/* .modal-dialog {
    max-width: 626px !important;
} */

.modal-md {
    max-width: 626px !important;
}

.modal-sm {
    max-width: 426px !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

.filter-text .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart {
    color: #000 !important;
    font-family: Raleway !important;
    font-size: 1.4rem !important;
    font-weight: 500 !important;
}

.filter-text .MuiOutlinedInput-notchedOutline {
    border-color: #DADADA !important;
    display: block;
    height: 4.5rem;
}

.filter-btn-delivery {
    width: 100%;
    height: 4rem;
    background: #135478;
    border: 1px solid #135478;
    border-radius: 4px;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
    color: #FFFFFF;
    margin-top: 2.5rem;
}

.filter-btn-pickup {
    width: 100%;
    height: 4rem;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Raleway;
    font-weight: 500;
    font-size: 1.5rem;
    color: #B2B2B2;
    margin-top: 2.5rem;
}

.filter-input select {
    margin-top: -2rem;
}

.radio-toolbar {
    margin-bottom: 5rem;
    margin-top: -2rem;
}
  
.radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}
  
.radio-toolbar label {
    width: 100%;
    height: 4rem;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Raleway;
    font-weight: 500;
    font-size: 1.5rem;
    color: #B2B2B2;
    margin-top: 2.5rem;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    line-height: 1.3;
    white-space: nowrap;
}
  
.radio-toolbar input[type="radio"]:checked + label {
    width: 100%;
    height: 4rem;
    background: #135478;
    border: 1px solid #135478;
    border-radius: 4px;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
    color: #FFFFFF;
    margin-top: 2.5rem;
}

.css-b62m3t-container {
    margin-top: -2rem;
}

  .css-319lph-ValueContainer {
    padding: 2px 34px !important;
}

.css-1d8n9bt {
    padding: 2px 34px !important;
}

.css-g1d714-ValueContainer {
    padding: 2px 34px !important;
}

.css-1hwfws3 {
    padding: 2px 34px !important;
}

@media (max-width: 768px) {
    .modal-md {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    .modal-content {
        height: auto !important;
        min-height: 100% !important;
        border-radius: 0;
    }
    .modal-sm {
        width: 85vw;
        max-width: 85vw !important;
        margin: auto;
    }
    .filter-button {
        position: absolute;
        left: 0;
        right: 0;
    }
    .dp-class {
        margin-top: -1.7rem;
    }
}

span.css-1okebmr-indicatorSeparator{
    display: none;
}

.filter-input input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: url('../Images/filter_datetime.svg') no-repeat;
}

.arrow {
    position: relative;
    float: right;
    width: 12px;
    margin-right: 1.4rem;
    opacity: 0.6;
    top: -2rem;
}

.filter-input .react-datepicker__tab-loop {
    position: absolute;
    z-index: 11111;
}

/* .filter-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    font-size: 1.2rem;
} */
.filter-input .react-datepicker-wrapper  {
    width: 100%;
}

.filter-input .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100% !important;
}

@media (max-width:400px){
    .filter-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        font-size: 1.2rem;
    }
    .filter-input .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        margin: 0.3rem !important;
        font-size: 1.2rem;
    }
    .filter-input .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        left: 11.5rem !important;
    }
    
    .filter-input .react-datepicker__navigation--previous {
        left: 2rem !important;
    }
}