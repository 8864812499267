@font-face {
    font-family: Raleway;
    src: url(../Fonts/RALEWAY-VARIABLEFONT_WGHT.TTF);
}

.faq-container {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    font-family: Raleway;
}

.faq-heading {
    width: 83%;
    margin-bottom: 20px;
}

.faq-container .faq-heading p {
    font-weight: 600;
    font-size: 1.75rem;
}

.faq-detail-container, .faq-detail-container-opened {
    width: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: fit-content;
    /* border: 2px solid black; */
}

details {
    width: 100%;
    margin-top: 0.6rem;
    margin-left: 35px;
    position: relative;
    display: flex;
    justify-content: center;
    top: 2px;
}

summary {
    width: 100%;
    list-style: none;
    position: relative;
    /* border: black 2px solid; */
    left: 10px;
}

.icon-image-active {
    width: 30px;
    position: absolute;
    /* right: 98%; */
    left: -10px;
    top: 12px
}

.icon-image-inactive {
    width: 10px;
    position: absolute;
    /* right: 98.6%; */
    left: -2px;
}

@media (min-width: 1400px) {
    .icon-image-active {
        width: 30px;
        position: absolute;
        right: 99%;
        /* top: 11px */
        left: -8px;
    }

    .icon-image-inactive {
        width: 10px;
        position: absolute;
        /* right: 99.7%; */
        left: -2px;
    }
}

@media (max-width: 768px) {
    .icon-image-active {
        width: 30px;
        position: absolute;
        /* right: 99%; */
        left: -10px;
        /* top: 11px */
    }

    .icon-image-inactive {
        width: 10px;
        position: absolute;
        /* right: 99.7%; */
        left: -2px;
    }
}

summary:focus {
    outline: none;
}

summary::-webkit-details-marker {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/right-arrow.svg);
    color: transparent;
}

details p {
    margin-top: 1.25rem;
    position: relative;
    left: 10px;
}

.faq-seperator {
    width: 80%;
    margin: 3rem auto;
    color: #DEE2E6;
}