table.report-container {
  page-break-after: always;
  width: 100%;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

.footer-space, .print-footer {
  height: 150px;
  page-break-before: always;
  border: "2px solid black"
}

.print-footer {
  display: table-footer-group;
  position: fixed;
  bottom: 8px;
  page-break-before: always;
}

@media print {
  @page {
    size: A4;
  }

  tr td {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}