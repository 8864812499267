.billing-main-comp {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
}

.main-container {
    margin: 2rem 1rem 1rem 1rem;
}

.billing-main-comp h4 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    color: #000000;
}

.billing-info-input {
    display: flex;
    flex-direction: column;
    position: relative;
}

.billing-info-input label {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 2;
    color: #495057;
}

.billing-input-text {
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 4px;
    height: 3.5rem;
    width: 95%;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    color: #1A203D;
    font-size: 1.3rem;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 1rem;
}

.billing-input-text:disabled {
    background-color: #F1F0F0;
}
.billing-input-text-area {
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 4px;
    height: 3.5rem;
    width: 98%;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    color: #1A203D;
    font-size: 1.3rem;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 1rem;
}

.billing-input-checkbox {
    height: 4rem;
    margin-top: 1rem;
    position: relative;
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.3rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 4px;
}

.container:hover input~.checkmark {
    background-color: #FFFFFF;
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 4px;
}

.container input:checked~.checkmark {
    background-color: #135478;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked~.checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.additional-info {
    height: 7rem;
}

.mt-8 {
    margin-top: 8rem;
}

.ic-mt {
  margin-top: 5rem;
}

.ic-mb-2 {
  margin-bottom: 2rem;
}
.mt-5 {
    margin-top: 5rem;
}

textarea:focus {
    outline: none;
}

.tnc_link {
    font-size: 1.3rem;
    color: #135478;
}

.main-login {
    margin-right: 1rem;
    margin-left: 1rem;
}

.main-login label {
    font-size: 1.3rem;
    color: #495057;
    margin-top: 1rem;
    margin-right: 0;
    margin-bottom: 0;
    line-height: 2;
}

.full-width {
    width: 100%;
}

.error-message {
    font-size: 1rem;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    margin-bottom: 0.7rem;
    color: #CE1640;
    font-family: 'Raleway';
}

.box {
    width: 23rem;
    height: auto;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 1rem;
    font-family: Raleway;
    padding: 7px 2px;
    position: relative;
    margin: 15px;
    border-radius: 4px;
  }

  .arrow-bottom::after {
    content: "";
    position: absolute;
    left: 30px;
    top: -10px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 12px solid #FFFFFF;
  }

.box img {
    margin-top: auto;
    margin-bottom: auto;
}

.error-message-display {
    font-size: 1rem;
    margin-top: 0.8rem;
    margin-bottom: 0.7rem;
    color: #FFFFFF;
    font-family: 'Raleway';
}

.login_back {
    margin-right: 1rem;
    cursor: pointer;
}

.billing-input-checkbox label {
    line-height: 1.7;
    padding-left: 25px;
}

.billing-input-checkbox .checkmark {
    position: absolute;
    left: -5px;
}

.loader {
    justify-content: center;
    align-items: center;
}

._loader {
    justify-content: center;
    align-items: center;
    margin-top: -5.5rem;
}

.modal-sm .filter-button {
    position: relative;
}

.payment-form {
    width: 40%;
    margin: 4rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-form button {
    height: 5.125rem;
    position: relative;
}

.payment-form button div {
    position: absolute;
    top: 30%;
    left: 50%;
}

.forgot-password {
    display: flex;
    justify-content: right;
    float: right;
    align-items: flex-end;
    color: #ED8D7D;
    margin-bottom: 1rem;
}

.forgot-password a {
    font-size: 1.2rem;
    font-family: Raleway;
    line-height: 0.5;
    cursor: pointer;
    float: right;
}

.tick-loader {
    margin-top: 5rem;
}

.tick-loader p {
    font-family: Raleway;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .billing-main-comp {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
    }
    .billing-input-text {
        width: 100%;
    }
    .billing-input-text-area {
        width: 100%;
    }
    .ic-mt {
      margin-top: 8rem;
    }
}

@media (max-width: 600px){
    .payment-form {
        width: 100vw;
        margin: 4rem auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .payment-form .form {
        width: 100%;
        padding: 15px;
    }
}


.error-field {
    background: rgba(220, 53, 69, 0.08);
    border: 1px solid #DC3545;
}

.captitalize {
    text-transform: capitalize;
}

button[disabled],
html input[disabled] {
    cursor: not-allowed;
}