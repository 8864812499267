@font-face {
    font-family: Raleway;
    src: url(../Fonts/RALEWAY-VARIABLEFONT_WGHT.TTF);
}

@font-face {
    font-family: Prata;
    src: url(../Fonts/Prata-Regular.ttf);
}

:root {
    --toastify-icon-color-success: #ED8D7D !important;
}


#root {
    height: 100%;
}

body {
    /* height: unset; */
    margin: 0;
    padding: 0;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
    text-align: unset;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
}

.ic-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
}

.height100 {
    height: 100%;
}

.ic-wrapper-lp {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
}

.container-main {
    text-align: center;
    justify-content: center;
    height: 70rem;
    width: 100%;
    background-image: url('../Images/asset.svg'), url('../Images/main-image.jpg');
    background-size: 73%, 101%;
    background-repeat: no-repeat, no-repeat;
    background-position: right 0, center -1px;
}

.icon_heading {
    color: #FFFFFF;
    margin-top: -30px;
}

.input-group {
    position: relative;
    justify-content: center;
    text-align: center;
    width: -webkit-fill-available;
}

.main-heading {
    color: white;
}

.main-heading h2 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    /* margin-top: 0.1rem; */
    line-height: 2.375rem;
    align-items: center;
    letter-spacing: 0.1em;
}

.main-carousel {
    margin-top: 10.7rem;
    background-color: rgb(19, 84, 120, 0.8);
    width: 100%;
    height: 23rem;
}

.main-carousel h4 {
    font-size: 4rem;
    font-weight: 400;
    font-family: Prata;
    line-height: 2.5;
    font-style: normal;
    margin-top: 3rem;
    color: #FFFFFF;
}

.main-carousel p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    font-size: 1.7rem;
    /* line-height: 1; */
    text-transform: uppercase;
    align-items: center;
}

.main-carousel input {
    width: 37.813rem;
    height: 4.4rem;
    border-radius: 0.75rem;
    color: #050505;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    font-family: Raleway;
    font-size: 1.5rem;
}

.main-carousel select {
    width: 37.813rem;
    height: 4.4rem;
    border-radius: 0.75rem;
    color: #050505;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    font-family: Raleway;
}

.main-carousel button {
    position: absolute;
    width: 8.836rem;
    height: 4.4rem;
    border-radius: 0.75rem;
    font-size: 1.5rem;
}

.main-carousel button:disabled {
    width: 8.836rem;
    height: 4.4rem;
    border-radius: 0.75rem;
    font-size: 1.5rem;
}

.main-logo {
    margin-top: 5rem;
    margin-left: 1.5rem;
    height: 320px;
    width: 280px;
}

.margin-added {
    margin-left: 7rem !important;
}

.input-icon {
    position: absolute;
    margin: 0.8rem;
    width: 3.063rem;
    height: 2.688rem;
    z-index: 999;
}

._container {
    text-align: center;
    color: #135478;
    height: 55rem;
    width: 100%;
    background-image: url('../Images/green_background.svg');
    background-size: 108%;
    background-repeat: no-repeat;
}

._container h4 {
    margin-top: 1.5rem;
    font-size: 3.5rem;
    font-family: Prata;
    margin-bottom: 4rem;
}

.footer {
    width: 100%;
    height: 30rem;
    background-color: #135478;
    background-image: url('../Images/asset.svg');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: right 0;
    z-index: 999;
}

.cart-logo {
    top: 1rem;
    right: 1rem;
    width: 4.5rem;
    height: 4.5rem;
    cursor: pointer;
}

.main-cart-div {
    position: relative;
    float: right;
    top: 0;
    right: 0;
    margin-top: 1rem;
    margin-right: 1rem;
}

.main-cart-div span {
    font-weight: 500;
    font-family: 'Raleway';
    position: absolute;
    right: 0.7rem;
    top: 1.1rem;
    color: #FFFFFF;
    font-size: 1rem;
    z-index: 999;
}

.footer-logo {
    margin-top: 3.063rem;
}

.fit-footer {
    height: calc(100vh - 10rem - 5.769rem);
}


.carousel-content {
    color: #FFFFFF;
}

.carousel-tag {
    font-size: 1.7rem;
    font-family: Raleway;
}

.carousal-date-input {
    display: block;
    box-sizing: border-box;
    height: 4rem;
    padding: 6px 5px 5px 40px;
    border: 1px solid #DADADA;
    border-radius: 4px;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 95%, #fff 0);
    background-position: -200px 0;
    background-size: 200px 100%;
    background-repeat: no-repeat;
    color: #000;
    font-family: Raleway;
    font-size: 1.4rem;
}

.carousal-date-input-error {
    display: block;
    box-sizing: border-box;
    height: 4rem;
    padding: 9px 5px 5px 40px;
    border: 1px solid #DADADA;
    border-radius: 4px;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 95%, #fff 0);
    background-position: -200px 0;
    background-size: 200px 100%;
    background-repeat: no-repeat;
    color: #000;
    font-family: Raleway;
    font-size: 1.4rem;
}

.carousel-input {
    padding-left: 4rem;
}

input:focus, select:focus {
    outline: none;
}

.loaderParent {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 9999;
  }

.dialog {
    z-index: 999999;
}

.answer-input-group {
    margin: auto;
    width: fit-content;
    position: relative;
}

.picker-input-group {
    margin: auto;
    width: fit-content;
    position: relative;
}

.carousel_button {
    margin-left: -5.8rem;
    height: 3.3rem;
    background-color: #ED8C7C;
    color: #FFFFFF;
    border: 1px solid #ED8C7C;
    font-family: Raleway;
    font-weight: 200;
    border-radius: 12px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

label {
    font-size: 10px;
    font-weight: 400;
    margin-right: 15rem;
    margin-bottom: 0;
    margin-top: 0.8rem;
    font-family: Raleway;
}

.carousal-inner {
    margin-right: auto;
    margin-left: auto;
}

.after-container {
    text-align: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.slider_box {
    height: 40rem;
    width: 34rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: -1rem;
    background-color: #FFFFFF;
    cursor: pointer;
}

.footer-first-button {
    margin-top: 1rem;
    height: 2.875rem;
    width: 12.188rem;
    border-radius: 3px;
    color: #FFFFFF;
    border: 1px solid #ED8C7C;
    background-color: #ED8C7C;
    font-family: Raleway;
    font-weight: 300;
    font-size: 1.375rem;
}

.footer-second-button {
    margin-top: 1rem;
    height: 2.875rem;
    width: 12.188rem;
    border-radius: 3px;
    color: #FFFFFF;
    border: 1px solid #C4D3C7;
    background-color: #C4D3C7;
    font-family: Raleway;
    font-weight: 300;
    font-size: 1.375rem;
}

.footer-second-button a {
    text-decoration: none;
    color: white;
}

.footer-buttons {
    display: flex;
    flex-flow: column wrap;
    align-content: flex-end;
    margin-bottom: 10px;
}

.main-footer {
    margin-top: 6rem;
    font-family: Raleway;
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 0.6;
}

.main-login-footer {
    width: fit-content;
    margin-top: 4.5rem;
    font-family: Raleway;
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 0.6;
}

.main-login-footer p {
    font-weight: 300;
    font-size: 1.7rem;
    font-family: Raleway;
    font-style: normal;
    cursor: pointer;
    width: fit-content;
    display: flex;
}

.main-login-footer p a, .main-footer p a {
    color: #FFFFFF;
    text-decoration: none;
}


.main-footer p {
    font-weight: 300;
    font-size: 1.7rem;
    font-family: Raleway;
    font-style: normal;
    cursor: pointer;
    width: fit-content;
    display: flex;
}

.footer-icons {
    color: #FFFFFF;
    margin-bottom: 2rem;
}

.footer-icon {
    margin-left: 1rem;
    margin-right: 1rem;
}

.f-icons {
    font-size: 1.7rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
}

.slider-container {
    display: flex;
    justify-content: left;
    /* height: 600px; */
    /* width: 4000px; */
    margin: 10px auto;
    transform: translateX(0);
    transition: all 0.5s ease-in-out;
}

.slider-text {
    color: #000000;
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
}

.slider-text-heading {
    font-family: Raleway;
    font-weight: 600;
    margin-top: 3rem;
    line-height: 1.5;
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-left: 1rem;
    margin-right: 1rem;
}

.slider-text-paragraph {
    font-family: Raleway;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    margin-left: 5.1rem;
    margin-right: 5.1rem;
    line-height: 1.5;
    font-weight: 400;
    font-size: 1.4rem;
}

.slider-barrier {
    position: absolute;
    top: 44%;
    margin-left: 1.5rem;
    color: #FFFFFF;
    height: 4rem;
    width: 31rem;
    line-height: 2.5;
    font-weight: 400;
    font-family: 'Raleway';
    white-space: nowrap;
}

.date-picker-icon-marging {
    margin-left: 3rem;
}

.package-image {
    width: 403px;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.footer-col-1 {
    width: 50%;
    margin-left: 8.313rem;
    margin-top: 2rem;
    line-height: 1;
    text-align: left;
    justify-content: left;
}

.footer-col-2 {
    width: 50%;
    text-align: right;
    justify-content: right;
    margin-right: 6.313rem;
    margin-top: 15rem;
}

.footer-row {
    display: flex;
    flex-direction: row;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.alice-carousel__stage-item {
    width: 48rem !important;
}

.carousal-indicators {
    width: -webkit-fill-available;
}

.carousel-indicators li {
    border: 1px solid #ED8D7D;
    margin: 3px;
}

.carousel-indicators .active {
    background-color: #ED8D7D !important;
    margin: 1.8px;
}

.input-group .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.MuiFormLabel-filled {
    display: none !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    font-size: 2rem !important;
}

.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today {
    font-size: 1.3rem !important;
}

.MuiTypography-root.MuiTypography-caption {
    font-size: 1.3rem !important;
    margin-left: 0.2rem !important;
    margin-right: 0.2rem !important;
}

.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    font-size: 1.3rem !important;
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    font-size: 1.3rem !important;
}

.input-group .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart {
    padding-left: 5rem !important;
}

.input-group .MuiOutlinedInput-notchedOutline {
    border: none;
}

.input-group .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #135478 !important;
}

.input-group .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: none;
}

.input-group .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
}

.loader-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #f6f8fb;
    opacity: 0.5;
    z-index: 1100;
}

.owl-carousel .owl-stage {
    height: 72rem !important;
}

.btn-close {
    background: url('../Images/modal_cross_icon.svg') center/2em auto no-repeat;
    border-radius: 1.25rem !important;
}

.modal-header .btn-close {
    margin: -0.5rem 1rem -0.5rem auto;
}

.image-arrow-down {
    z-index: 1;
    position: absolute;
    right: 60.5rem;
    top: 5rem;
    width: 1.5rem;
}

.dp-icon {
    display: none;
}

.Toastify__progress-bar--success {
    background-color: #ED8D7D !important;
}


.Toastify__toast--success::after {
    background-color: #ED8D7D;
}


@media screen and (min-width: 768px) {
    .carousel-indicators {
        width: -webkit-fill-available;
    }
}

@media (max-width: 768px) {
    .container-main {
        text-align: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-image: url('../Images/mobile_landing_layout.svg'), url('../Images/main-image.jpg');
        background-size: 115%, 350%;
        background-repeat: no-repeat, no-repeat;
        background-position: right 0, center -1px;
    }

    ._container {
        text-align: center;
        color: #135478;
        height: 41rem;
        width: 100%;
        background-image: url('../Images/green_background.svg');
        background-size: 225%;
        background-repeat: no-repeat;
    }

    .footer-col-1 {
        width: 50%;
        margin-left: 2.313rem;
        margin-top: 3.5rem;
        line-height: 1;
        text-align: left;
        justify-content: left;
    }

    .footer-col-2 {
        width: 50%;
        text-align: right;
        justify-content: right;
        margin-right: 2.313rem;
        margin-top: 15.5rem;
    }

    .main-footer {
        margin-top: 7rem;
    }

    .main-login-footer {
        margin-top: 5.5rem;
    }

    .footer-logo {
        margin-top: 0rem;
    }

    .slider_box {
        height: 28.5rem;
        width: 25.188rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: -1rem;
        background-color: #FFFFFF;
    }

    .main-heading h2 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.375rem;
        align-items: center;
        letter-spacing: 0.1em;
    }

    .main-carousel {
        margin-top: 2rem;
        background-color: rgb(19, 84, 120, 0.8);
        width: 100%;
        height: 16.688rem;
    }

    .main-carousel h4 {
        font-size: 2.813rem;
        font-weight: 400;
        font-family: Prata;
        line-height: 2;
        font-style: normal;
        margin-top: 1rem;
    }

    .main-carousel p {
        font-family: Raleway;
        font-style: normal;
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 101.5%;
        text-transform: uppercase;
        align-items: center;
    }

    .main-carousel input {
        width: 29.813rem;
        height: 3.4rem;
        border-radius: 0.75rem;
        color: #050505;
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        font-family: Raleway;
    }

    .main-carousel select {
        width: 28.813rem;
        height: 3.35rem;
        border-radius: 0.75rem;
        color: #050505;
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        font-family: Raleway;
    }

    .main-carousel button {
        width: 7.836rem;
        height: 3.5rem;
        border-radius: 0.75rem;
        font-size: 1.5rem;
    }

    .input-icon {
        position: absolute;
        margin: 0.4rem;
        width: 3.063rem;
        height: 2.688rem;
    }

    ._container h4 {
        font-size: 2.813rem;
    }

    .carousel-input {
        padding-left: 4rem;
    }

    .slider_box {
        height: 29.5rem;
        width: 25.188rem;
    }

    .slider-text-heading {
        font-family: Raleway;
        font-weight: 600;
        margin-top: 2rem;
        line-height: 1.15;
        font-size: 1.25rem;
        text-transform: uppercase;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .slider-text-paragraph {
        font-family: Raleway;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        margin-left: 3.1rem;
        margin-right: 3.1rem;
        line-height: 1.4;
        font-weight: 400;
        font-size: 1.25rem;
    }

    .slider-barrier {
        top: 41%;
        margin-left: 1rem;
        height: 2.313rem;
        width: 23.25rem;
        line-height: 2;
        font-size: 1.3rem;
    }

    .alice-carousel__stage-item {
        width: 27rem !important;
    }

    .carousel-indicators {
        bottom: 0px;
        margin-left: 18% !important;
    }

    .package-image {
        width: 278px !important;
        height: 137px !important;
    }

    .input-group .MuiOutlinedInput-input.MuiInputBase-input {
        padding-left: 5rem !important;
    }

    .date-picker-icon-marging {
        margin-left: 1rem;
    }

    .owl-carousel .owl-stage {
        height: 57rem !important;
    }

    .date-input {
        width: 29.813rem !important;
        height: 3.4rem !important;
    }

    .carousel_button {
        margin-left: -7.8rem;
    }

    .carousel-indicators .active {
        margin: 2px;
    }

    .footer {
        width: 100%;
        height: 30rem;
        background-color: #135478;
        background-image: url('../Images/asset.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: right 0;
    }

    .image-arrow-down {
        z-index: 1;
        position: absolute;
        right: 15rem;
        top: 4rem;
        width: 1.2rem;
    }
}


.mp-input button {
    margin-top: -4.4rem;
    margin-left: 11rem;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    top: 38px;
    left: 2.5rem !important;
}

.react-datepicker__navigation--previous {
    top: 38px !important;
    left: -72px !important;
}


input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    position: absolute;
    opacity: 1;
    background: url('../Images/calendar_icon.png') no-repeat;
    width: 30px;
    height: 30px;
    margin-left: -3.28rem;
    margin-top: 0.5rem;
    cursor: pointer;
}

input::-webkit-datetime-edit {
    position: relative;
}

input::-webkit-datetime-edit-fields-wrapper {
    position: relative;
}

@media (max-width: 668px) {
    .main-carousel {
        margin-top: 2rem;
        background-color: rgb(19, 84, 120, 0.8);
        width: 100%;
        height: 20.688rem;
    }

    input[type="datetime-local"]::before {
        width: 100%;
        content: attr(placeholder) !important;
    }

    .safari-view input[type="datetime-local"]::before {
        width: 100%;
        content: attr(placeholder) !important;
    }

    input[type="datetime-local"]:focus:before,
    input[type="datetime-local"]:not([value=""]):before {
        display: none;
    }

    .safari-view input[type="datetime-local"]:focus:before,
    .safari-view input[type="datetime-local"]:not([value=""]):before {
        display: none;
    }

    .dp-icon {
        display: block;
    }

    .mp-input button {
        margin-top: -3.5rem;
        margin-left: 8rem;
    }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        top: 35px;
        left: 6.5rem !important;
    }
    
    .react-datepicker__navigation--previous {
        top: 35px !important;
        left: -42px !important;
    }
}
@media (min-width: 669px) and (max-width:768px){
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        top: 42px;
        left: 3.5rem !important;
    }
    
    .react-datepicker__navigation--previous {
        top: 42px !important;
    }
}
.modal-open #owl-demo {
    width: 100% !important;
}

.modal-open #owl-demo .item {
    width: 100% !important;
}

.signup-checkbox {
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border-width: 0px;
    outline: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 4px;
}

@media (max-width: 500px) {
    .main-footer {
        margin-top: 5.2rem;
    }
    .main-login-footer {
        margin-top: 3.2rem;
    }
    .main-login-footer p, .main-footer p {
        flex-direction: column;
    }
    .main-login-footer p, .main-footer p {
        line-height: 0.6;
    }
    .footer-number-separator {
        display: none;
    }
}

@media (max-width:400px){
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        top: 32.5px;
        left: 1.5rem !important;
    }
    
    .react-datepicker__navigation--previous {
        top: 32.5px !important;
        left: -8.5rem !important
    }
}