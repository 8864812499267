.react-datepicker__month-container {
    position: relative;
}

.react-datepicker-popper {
    position: unset !important;
    transform: unset !important;
}

.react-datepicker__input-container {
    min-width: 300px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 5px 0px !important
}

.react-datepicker__input-container input {
    padding-left: 50px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 1rem !important;
}


.carousel-inner {
    z-index: 99999;
}

.carousel_button_lib {
    position: absolute;
    margin-left: -5.8rem;
    height: 3.3rem;
    background-color: #ED8C7C;
    color: #FFFFFF;
    border: 1px solid #ED8C7C;
    font-family: Raleway;
    font-weight: 200;
    border-radius: 12px;
    position: absolute;
    top: 44px;
    right: 0px;
}


.dp-icon-lib {
    position: absolute !important;
}

.icon-closed, .icon-opened {
    left: 5px;
}

.picker-closed {
    right: 0px;
}

.picker-opened {
    right: 0px;
}

@media (max-width: 668px) {
    .carousel_button_lib {
        top: 35px;
    }
}

li:focus {
    outline: none !important;
}

@media (max-width: 768px) {
    .react-datepicker__input-container {
        width: 180px !important;
    }
    .icon-opened {
        left: 32px;
    }
    .picker-opened {
        right: 27px;
    }
}
@media (max-width: 400px) {
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        font-size: 1.2rem;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        margin: 0.3rem !important;
        font-size: 1.2rem;
    }
    .icon-opened {
        left: 8px;
    }
    .picker-opened {
        right: 0px;
    }
}