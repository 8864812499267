.modal-button {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    height: 4rem;
    color: #FFFFFF;
    background: #ED8D7D;
    border: 1px solid #ED8D7D;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
}

.modal-button:disabled {
    opacity: 0.5;
}
.show {
    z-index:99999;
}
.confirmBtn {
    width: 100%;
    height: 4rem;
    color: #FFFFFF;
    background: #ED8D7D;
    border: 1px solid #ED8D7D;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
}

.modal-cancel-button {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    height: 4rem;
    color: #FFFFFF;
    background: #C5D3C7;
    border: 1px solid #C5D3C7;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1.5rem;
}

.cart-error-div {
    background: rgba(220, 53, 69, 0.08);
}

.span-modal-error {
    color: #DC3545;
}

.cursor-pointer {
    cursor: pointer;
}

.mb15 {
    margin-bottom: 0.5rem;
}

.cross-icon {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    padding-right: 1.5rem;
    padding-top: 1rem;
    top: 0;
    height: 3rem;
}

@media (max-width: 768px) {
    .modal-cancel-button {
        position: absolute;
        width: 45%;
    }

    .modal-button {
        position: absolute;
        width: 45%;
    }
}