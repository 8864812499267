@font-face {
  font-family: Raleway;
  src: url(../Fonts/RALEWAY-VARIABLEFONT_WGHT.TTF);
}

.form {
  width: 40vw;
  min-width: 500px;
  align-self: center;
  font-family: Raleway;
  /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px; */
  padding: 40px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb30 {
  margin-bottom: 30px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-family: Raleway;
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.button {
  background: #ED8D7D;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.confirming_message {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.confirming_message span {
  font-weight: 700;
}

.button:hover {
  filter: contrast(115%);
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.payment_header {
  text-align: center;
  justify-content: center;
  height: 50rem;
  width: 100%;
  background-image: url('../Images/asset.svg'), url('../Images/main-image.jpg');
  background-size: 73%, 101%;
  background-repeat: no-repeat, no-repeat;
  background-position: right 0, center -150px;
}

.payment_header .main-logo {
  margin-top: 5rem;
  margin-left: 1.5rem;
  height: 320px;
  width: 280px;
}


.payment_header .main-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.poweredByStripe {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #696F79;
}

.payment-footer-container {
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.logo_address_container .footer-logo {
  height: fit-content;
}

.payment-footer {
  margin-top: 6rem;
  font-family: Raleway;
  color: #FFFFFF;
  font-size: 1.5rem;
  font-weight: 400;
  /* line-height: 0.6; */
}

.payment-footer p {
  font-weight: 300;
  font-size: 1.7rem;
  font-family: Raleway;
  font-style: normal;
  cursor: pointer;
}

@media (max-width: 768px) {
  .payment_header {
    text-align: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-image: url('../Images/mobile_landing_layout.svg'), url('../Images/main-image.jpg');
    background-size: 115%, 150%;
    background-repeat: no-repeat, no-repeat;
    background-position: right 0, center -130px;
  }

  .payment_header .main-heading {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .payment_header .main-logo {
    margin-top: 1rem;
    margin-left: 1.5rem;
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 520px) {
  .payment_header {
    text-align: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-image: url('../Images/mobile_landing_layout.svg'), url('../Images/main-image.jpg');
    background-size: 115%, 150%;
    background-repeat: no-repeat, no-repeat;
    background-position: right 0, center -25px;
  }

  .payment_header .main-heading {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .payment_header .main-logo {
    margin-top: 1rem;
    margin-left: 1.5rem;
    height: 250px;
    width: 250px;
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .form {
    width: 80vw;
    min-width: initial;
  }
}