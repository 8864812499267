.text-align-center {
    text-align: center;
}

.my-border {
    border: 2px solid black;
}

.printDraftOrder {
    display: none;
}
@media print {
    .printDraftOrder {
        display: block;
    }
}
.cart-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.cart-heading .download-icon {
    cursor: pointer;
    margin-bottom: 8px;
    margin-left: 10px;
}

.cart-detail-main-container {
    overflow: hidden;
}
.emptyCartContainer {
    height: calc(100vh - 7rem - 20rem);
}
.cart-list {
    margin-top: 25px;
    display: flex;
    align-items: flex-start;
}
.vendor-package-headings {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}
.cart-amount-side .item-quantity {
    width: 15%;
}

.cart-list .item-description {
    width: 50%;
}
.cart-amount-side {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.cart-amount-side .item-amount {
    width: 30%;
    text-align: left;
    position: relative;
}

.cart-amount-side .item-total {
    width: 30%;
    position: relative;
    text-align: right;
}

.cart-amount-side .item-amount p  {
    position: absolute;
    font-size: 7px;
    color: #787885;
    left: 3px;
}

.cart-amount-side .item-total p  {
    position: absolute;
    font-size: 7px;
    color: #787885;
    right: 10px;
}
.vendor-package-headings .item-amount {
    width: 30%;
    text-align: left;
}
.vendor-package-headings .item-total {
    width: 30%;
    text-align: center;
}


@media (min-width: 1440px) and (max-width: 1450px) {
    .cart-list .item-description {
        /* border: 2px solid black; */
        width: 60%;
    }
    .cart-amount-side {
        /* border: 2px solid black; */
        width: 40%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    
    .cart-amount-side .item-amount {
        width: 30%;
        text-align: left;
        position: relative;
    }
    
    .cart-amount-side .item-total {
        width: 30%;
        position: relative;
        text-align: right;
    }
}
@media (max-width: 768px){
    .cart-list .item-description {
        /* border: 2px solid black; */
        width: 60%;
    }
    .cart-amount-side {
        /* border: 2px solid black; */
        width: 40%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    
    .cart-amount-side .item-amount {
        width: 30%;
        text-align: left;
        position: relative;
    }
    
    .cart-amount-side .item-total {
        width: 30%;
        position: relative;
        text-align: right;
    }
}

@media (max-width: 510px){
    .cart-list .item-description {
        /* border: 2px solid black; */
        width: 50%;
    }
    .cart-amount-side {
        /* border: 2px solid black; */
        width: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    
    .cart-amount-side .item-amount {
        width: 30%;
        text-align: left;
        position: relative;
    }
    
    .cart-amount-side .item-total {
        width: 30%;
        position: relative;
        text-align: right;
    }
}

@media (max-width: 410px){
    .cart-list .item-description {
        font-size: 14px;
    }
    .cart-list .item-quantity {
        font-size: 14px;
    }
}
.cart-detail-headings {
    font-weight: 700;
}

.cart-detail-value {
    font-weight: 700;
    text-align: right;
}

.cart-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-check-input:focus {
    box-shadow: none;
}
input[type='checkbox']:focus, input[type='radio']:focus {
    outline: 0;
    offset: 0;
}

.cursorActive {
    cursor: pointer;
}

.cart-option-heading {
    margin-bottom: 25px;
    margin-top: 2rem;
    font-weight: 700;
}

.cart-subOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.cart-supplement-option {
    width: 70%;
}

.cart-supplement-option form {
    width: 100%;
    position: relative;
    height: 24px;
}

.cart-supplement-option form .form-check label {
    width: 100%;
    font-size: 14px;
    position: absolute;
    bottom: 1.5px;
}

.cart-supplement-option form .form-check {
    padding-right: 10px;
    width: 100%;
}

.cart-supplement-option form .form-check input {
    position: absolute;
    bottom: 2px;
}

.cart-res-options {
    width: 100%;
    display: flex;
    grid-template-columns: auto auto auto auto auto;
    justify-items: self-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-check-input:checked {
    background-color: #135478;
    border-color: #135478;
}

.editableMode {
    color: #135478;
    font-weight: 500;
}

@media (max-width: 1024px) {
    .cart-res-options {
        width: 100%;
        display: flex;
        /* grid-template-columns: auto auto auto auto; */
        justify-items: self-start;
        justify-content: space-between;
        /* flex-wrap: wrap; */
    }
}

@media (max-width:786px) {
    .cart-options {
        display: grid;
        justify-items: self-start;
        grid-template-columns: 1fr 1fr;
    }

    .cart-options .cart-subOptions {
        margin: 5px;
    }

    .cart-options .cart-subOptions span {
        font-size: 15px;
    }

    .cart-res-options {
        width: 100%;
        display: flex;
        justify-items: self-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}


/* @media (max-width: 425px) {
    .cart-supplement-option form .form-check input {
        margin-top: 2.5%;
    }
    .cart-supplement-option form .form-check label {
        margin-top: 0;
    }
} */

@media (max-width:375px) {
    .cart-options .cart-subOptions span {
        font-size: 11px;
    }

    .cart-supplement-option form .form-check label {
        width: 100%;
        font-size: 13px;
        bottom: 3px;
    }
}