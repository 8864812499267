@font-face {
    font-family: Raleway;
    src: url(../Fonts/RALEWAY-VARIABLEFONT_WGHT.TTF);
}

@font-face {
    font-family: Prata;
    src: url(../Fonts/Prata-Regular.ttf);
}

.first-confirmation-part {
    justify-content: center;
    text-align: center;
    margin: 4rem 8rem;
}

.first-part {
    display: block;
}

.first-confirmation-part .confirmation_tick {
    width: 7rem;
    height: 7rem;
}

.first-confirmation-part h2 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    color: #212529;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.first-confirmation-part p {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    text-align: center;
    color: #212529;
    line-height: 0.8;
}

.first-confirmation-part h5 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 28px;
    text-align: center;
    color: #212529;
}

.second-confirmation-part {
    width: 60%;
    margin: 0 auto;
    padding-top: 2rem;
}

.second-confirmation-block {
    background-color: #F5F5F5;
    text-align: left;
    padding: 2rem 2rem 0.4rem 2rem;
}

.second-confirmation-part h5 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 28px;
    text-align: left;
    color: #212529;
}

.second-confirmation-block h6 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    color: #212529;
}

.second-confirmation-block p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    color: #212529;
}

.second-confirmation-block .user-details-text {
    margin: 1rem 0 2.5rem 0;
}

.create-order-button {
    width: 60%;
    margin: 0 auto;
    padding-top: 2rem;
}

.order-confirmation-cart .cart-detail-main-container {
    overflow-y: auto;
    overflow-x: hidden;
}

.create-another-order-button {
    width: 75%;
}
@media (max-width: 768px) {
    .first-confirmation-part {
        margin: 3rem 1rem 2rem 1rem;
        text-align: left;
    }
    .first-part {
        display: flex;
    }
    .first-part h2 {
        margin-top: 2rem;
        margin-left: 2rem;
        font-size: 1.9rem;
        line-height: 0.5;
    }
    .first-part p {
        margin-left: 2rem;
        margin-top: 0 !important;
    }
    .first-confirmation-part p {
        text-align: left;
        line-height: 1.2;
        margin-top: 2rem;
    }
    .first-confirmation-part h5 {
        text-align: left;
    }
    .second-confirmation-part {
        margin: 0 auto 2rem auto;
        width: 96%;
        padding-top: 0;
    }
    .create-order-button {
        width: 96%;
        padding-top: 8rem;
    }
}

.capitalize{
    text-transform: capitalize;
}