.redirect-btn {
  text-align: center;
  width: 20rem !important;
  margin: 5px;
}

.additional-text-color{
    color: #ED8D7D;
}

.error-div {
  min-height: 100%;
  /* min-height: 450px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
