

.header-container{
    display:flex;
    flex-direction: row;
    background:#F6F6F6; 
    height:7rem;
    width:100%;
    padding:13px 15px 10px 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    z-index: 999;
    position: relative;
}

.logo-div{
    width: 50%;
    justify-content: left;
}
.cart-div{
    width: 50%;
    text-align:right;
    justify-content:right;
    align-self: center;
}

.cart-div .single-digit-span {
    font-weight: 500;
    font-family: 'Raleway';
    position: absolute;
    right: 1.4rem;
    color: #FFFFFF;
    font-size: 1rem;
}

.cart-div .double-digit-span {
    font-weight: 500;
    font-family: 'Raleway';
    position: absolute;
    right: 1.2rem;
    color: #FFFFFF;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .header-container{
        display:flex;
        flex-direction: row;
        background:#FFFFFF; 
        height:7rem;
        width:100%;
        padding:13px 15px 10px 15px;
    }
    .logo-div{
        padding-left: 7rem;
        width: 80%;
        justify-content: left;
        text-align: center;
    }
    .cart-div{
        width: 20%;
        text-align:right;
        justify-content:right;
        align-self: center;
    }    
}
